<template>
	<div class="section_wrap pt-50">
		<div class="subindex_wrap">
			<div class="subindex_head">
				<div class="container">
					<div class="subindex_mainbox">
						<div class="myphoto">
							<img
								v-if="item.profle_nft_card_img_url"
								:src="$request.upload_url(item.profle_nft_card_img_url)" alt="item.file.name"
							>
							<img
								v-else
								:src="require('@/assets/image/@noimage.png')" alt="noimage">
						</div>
						<div class="myintroduce">
							<b class="name">{{  item.nickname }}</b>
							<p class="desc">{{ item.state_message }}</p>
						</div>
						<div class="interest_wrap">
							<span
								v-for="(fav, index) in item_fav"
								:key="'fav_' + index"
								class="inter_40"
								:class="'inter_40_' + fav"
							><em class="hide">{{ fav }}</em></span>
						</div>
					</div>
					<div class="subindex_head_setting">
						<button class="btn_profile_setting" @click="$emit('to', { name: 'mafia101'})"><span class="hide">{{  $language.common.edit_profile }}</span></button>
					</div>
				</div>
			</div>
			<div class="subindex_item">
				<div class="container">
					<div class="subindex_box_1">
						<ul class="settings_list">
							<li class="settings_list_item">
								<button
									@click="copy"
								>
									<div class="cell_area">
										<div class="cell_tit">{{  $language.common.number_member }}</div>
										<div class="cell_action" style="font-size: 1.4rem; font-weight: 500">{{ user.member_number }} <i class="mdi mdi-content-copy" style="color: #333"></i></div>
									</div>
								</button>
							</li>
							<li class="settings_list_item">
								<button
								>
									<div class="cell_area">
										<div class="cell_tit">{{  $language.common.number_phone }}</div>
										<div class="cell_action" style="font-size: 1.4rem; font-weight: 500">{{ user.mphone_number }}</div>
									</div>
								</button>
							</li>
							<li class="settings_list_item">
								<button
									@click="toCalculate"
								>
									<div class="cell_area">
										<div class="cell_tit">{{  $language.calculate.title_calculate }} <!-- 카르텔 정산 --></div>
										<div class="cell_action"><i class="icon icon-arrow-right"></i></div>
									</div>
								</button>
							</li>
							<li
								v-if="false"
								class="settings_list_item"
							>
								<button
									@click="$emit('to', { name: 'SaleNftList'})"
								>
									<div class="cell_area">
										<div class="cell_tit">런치패드 <!-- MY 불렛 --></div>
										<div class="cell_action"><i class="icon icon-arrow-right"></i></div>
									</div>
								</button>
							</li>
							<li
								v-if="false"
								class="settings_list_item"
							>
								<button
									@click="$emit('to', { name: 'PointList'})"
								>
									<div class="cell_area">
										<div class="cell_tit">{{  $language.common.myHeart }} <!-- MY 불렛 --></div>
										<div class="cell_action"><i class="icon icon-arrow-right"></i></div>
									</div>
								</button>
							</li>
							<li class="settings_list_item">
								<button
									@click="$emit('to', { name: 'MyShorts'})"
								>
									<div class="cell_area">
										<div class="cell_tit">{{  $language.shorts.title_my_shorts }} <!-- 등록 숏플 --></div>
										<div class="cell_action"><i class="icon icon-arrow-right"></i></div>
									</div>
								</button>
							</li>
							<li class="settings_list_item">
								<button
									@click="$emit('to', { name: 'mafia102'})"
								>
									<div class="cell_area">
										<div class="cell_tit">{{  $language.common.friend }} <!-- 친구 --></div>
										<div class="cell_action"><i class="icon icon-arrow-right"></i></div>
									</div>
								</button>
							</li>
							<li class="settings_list_item">
								<button @click="$emit('to', { name: 'mafia105'})">
									<div class="cell_area">
										<div class="cell_tit">{{  $language.common.notify }} <!-- 알림 --></div>
										<div class="cell_action"><i class="icon icon-arrow-right"></i></div>
									</div>
								</button>
							</li>
							<li
								v-if="false"
								class="settings_list_item"
							>
								<button
									@click="setDarkMode"
								>
									<div class="cell_area">
										<div class="cell_tit">{{  $language.common.dark_mode }} <!-- 다크모드 --></div>
										<div class="cell_action">
											<p class="toggle-group">
												<!-- TODO : 수신 허용시 .on 클래스 추가 -->
												<span
													class="btn-toggle"
													:class="{ on: user.dark_mode == 'Y' }"
												><em class="hide">{{  $language.common.dark_mode }} <!-- 다크모드 --></em></span>
											</p>
										</div>
									</div>
								</button>
							</li>
							<li
								v-if="false"
								class="settings_list_item"
							>
								<button @click="$emit('to', {name: 'mafiaCredit'}) ">
									<div class="cell_area">
										<div class="cell_tit">{{ $language.mypage.credit_management }} <!-- 신용카드 관리 --> </div>
										<div class="cell_action"><i class="icon icon-arrow-right"></i></div>
									</div>
								</button>
							</li>
							<li
								v-if="false"
								class="settings_list_item"
							>
								<button
									@click="$emit('to', { name: 'MypageSubscribeList'})"
								>
									<div class="cell_area">
										<div class="cell_tit">{{  $language.subscribe.title_subscribe_total_history }} <!-- 구독 통합 내역 --></div>
										<div class="cell_action"><i class="icon icon-arrow-right"></i></div>
									</div>
								</button>
							</li>

							<!-- NFT 결제 내역 -->
							<li
								v-if="false"
								class="settings_list_item"
							>
								<button @click="$emit('to', {name: 'NftHistory'}) ">
									<div class="cell_area">
										<div class="cell_tit">{{ $language.payment.nft_history }}</div> <!-- nft 결제 내역 -->
										<div class="cell_action"><i class="icon icon-arrow-right"></i></div>
									</div>
								</button>
							</li>

                            <!-- 개인정보 보호 -->
							<li class="settings_list_item">
								<button @click="$emit('to', {name: 'mafia111'}) ">
									<div class="cell_area">
										<div class="cell_tit">{{ $language.agreement.privacy_defence }} <!-- 개인정보 보호 --></div>
										<div class="cell_action"><i class="icon icon-arrow-right"></i></div>
									</div>
								</button>
							</li>

							<li
								v-if="is_cartel_request"
								class="settings_list_item"
							>
								<button @click="toCartelRequest">
									<div class="cell_area">
										<div class="cell_tit">{{ $language.cartel.cartel_request }}<!-- 카르텔 개설 요청 --></div>
										<div class="cell_action"><i class="icon icon-arrow-right"></i></div>
									</div>
								</button>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="subindex_item">
				<div class="container">
					<div class="subindex_box_1">
						<ul class="settings_list">
							<li class="settings_list_item">
								<button @click="$emit('to', { name: 'notice'})">
									<div class="cell_area">
										<div class="cell_tit">{{  $language.common.notice }}<!-- 공지사항 --></div>
										<div class="cell_action"><i class="icon icon-arrow-right"></i></div>
									</div>
								</button>
							</li>
							<li class="settings_list_item">
								<button @click="$emit('to', { name: 'faq'})">
									<div class="cell_area">
										<div class="cell_tit">FAQ</div>
										<div class="cell_action"><i class="icon icon-arrow-right"></i></div>
									</div>
								</button>
							</li>
							<li
								v-if="is_app"
								class="settings_list_item"
							>
								<button @click="$emit('to', { name: 'mafia117'})">
									<div class="cell_area">
										<div class="cell_tit">{{  $language.common.release_info }}</div>
										<div class="cell_action"><span class="text">{{ version }}</span><i class="icon icon-arrow-right"></i></div>
									</div>
								</button>
							</li>
							<li
								v-if="is_sample"
								class="settings_list_item"
							>
								<button @click="$emit('to', { name: 'reset'})">
									<div class="cell_area">
										<div class="cell_tit">{{  $language.common.test_version }}</div>
										<div class="cell_action"><span class="text">{{  $language.common.delete_data }}</span><i class="icon icon-arrow-right"></i></div>
									</div>
								</button>
							</li>
							<li
								class="color-white"
							>
								{{ tt }}
							</li>
							<li
								v-if="false"
								class="settings_list_item"
							>
								<button @click="copy">
									<div class="cell_area">
										<div class="cell_tit">{{ device_token}}</div>
									</div>
								</button>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div
				v-if="false"
				class="subindex_item"
			>
				<div class="container">
					<div class="subindex_box_1">
						<ul class="settings_list">
							<li class="settings_list_item">
								<button @click="onLanguage">
									<div class="cell_area">
										<div class="cell_tit">{{ $language.mypage.language_in_use }}</div>
										<div class="cell_action" style="font-size: 1.4rem; font-weight: 500">{{ text_language }}</div>
									</div>
								</button>
							</li>
						</ul>
					</div>
				</div>
			</div>

			<div @click="toTest" class="pa-10"></div>
		</div>

		<popup_select
			v-if="is_langauge"
			@cancel="is_langauge = false"
		>
			<template
				v-slot:title
			>{{ $language.mypage.select_language }}</template>
			<template
				v-slot:list
			>
				<li
					style="padding: 10px 0"
					@click="setLanguage('kr')"
				>한국어</li>
				<li
					style="padding: 10px 0"
					@click="setLanguage('en')"
				>english</li>
			</template>
		</popup_select>
	</div>
</template>

<script>
	import popup_select from "@/view/Layout/PopupSelect";
	export default {
		name: 'myPage'
		, components: {popup_select}
		, props: ['Axios', 'callBack', 'user']
		, data: function(){
			return {
				program: {
					name: this.$language.common.mypage
					, not_footer: true
					, type: 'mypage'
					, title: this.$language.common.mypage
					, from: 'main'
					, callBack: {
						name: 'pin_update'
					}
				}
				, item: {

				}
				, main_nft: {

				}
				, is_langauge: false
				, tt: {
					s_at: sessionStorage.getItem('mafinft_AT')
					, s_cer: sessionStorage.getItem('mafinft_certfc')
					, l_at: localStorage.getItem('mafinft_AT')
					, l_cer: localStorage.getItem('mafinft_certfc')
				}
			}
		}
		, computed: {
			is_sample: function(){
				let t = false
				if(process.env.VUE_APP_TYPE == 'sample'){
					t = true
				}

				return t
			}
			, device_token: function(){
				let uuid = this.$encodeStorage.getItem('_uuid')

				return uuid
			}
			, is_app: function(){
				let t = false
				try{
					this.$common.inAppWebviewCommunity('android', 'is_app')
					t = true
				}catch (e){
					t = false
				}
				return t
			}
			, item_fav: function(){
				let fav = []
				if(this.item.concern_sphere_list){

					this.item.concern_sphere_list.filter(function(item){
						switch(item.concern_sphere_code){
							case 'CM00400001':
								fav.push('music')
								break;
							case 'CM00400002':
								fav.push('art')
								break;
							case 'CM00400003':
								fav.push('fashion')
								break;
							case 'CM00400004':
								fav.push('influence')
								break;
						}
					})
				}
				return fav
			}
			, version: function() {
				let t = this.$encodeStorage.getSessionVersion()
				return  t
			}
			, text_language: function(){
				let t = '한국어'

				let language = localStorage.getItem('language')
				switch (language){
					default:
						t = '한국어'
						break
					case 'en':
						t = 'english'
						break;
				}

				return t
			}
			, is_cartel_request: function(){
				let t = true
				if(this.$common.getMobile() =='ios' && this.$common.isApp()){
					t = false
				}
				return t
			}
			, is_ios_app: function(){
				let t = false
				if(this.$common.isApp() && this.$common.getMobile() == 'ios'){
					t = true
				}
				return t
			}
		}
		,methods: {
			getData: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_mypage_main_info
						, data: {
							member_number: this.user.member_number
						}
						, type: true
					})
					if(result.success){
						this.item = result.data
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			,clipBoard: function (val){
				const t = document.createElement("textarea");
				document.body.appendChild(t);
				t.value = val;
				t.select();
				document.execCommand('copy');
				document.body.removeChild(t);
			}

			,copy: function (){
				this.clipBoard(this.user.member_number);
				this.$bus.$emit('notify',  { type: 'success', message: this.$language.common.copied_member_number})
			}

			,onLanguage: function(){
				this.is_langauge = true
			}
			, setLanguage: function(type){
				localStorage.setItem('language', type)
				this.$bus.$emit('on', true)
				this.is_langauge = false
				window.location.reload()
			}
			, setDarkMode: function(){
				if(this.user.dark_mode == 'Y'){
					localStorage.setItem('dark_mode', 'N')
					this.$set(this.user, 'dark_mode', 'N')
				}else{
					localStorage.setItem('dark_mode', 'Y')
					this.$set(this.user, 'dark_mode', 'Y')
				}
			}
			, toCartelRequest: function(){
				if(this.user.mphone_self_certfc_fg == 'Y'){
					this.$bus.$emit('to', {name: 'CartelRequestList'})
				}else{
					this.$bus.$emit('onPersonalConfirm', {name: 'CartelRequestList'})
				}
			}
			, toTest: function(){
				this.$bus.$emit('to', { name: 'test_otp_confirm'})
			}
			, toCalculate: function(){
				if(this.user.mphone_self_certfc_fg == 'Y'){
					this.$bus.$emit('to', {name: 'SubscribeReport'})
				}else{
					this.$bus.$emit('onPersonalConfirm', {name: 'SubscribeReport'})
				}
			}
		}
		,async created() {
			console.log(this.program)
			await this.getData()
			await this.$emit('onLoad', this.program)
		}
		,watch: {
			callBack: {
				handler: function(call){
					if(call.name == 'pin_update'){
						this.$emit('to', { name: 'pin', params: {pin_type: 'update'}})
					}
				}
			}
		}
	}
</script>